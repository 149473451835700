import React, { useCallback, useEffect, useState } from "react"
import PropTypes from "prop-types"
import pageLoader from "@/public/icons/page-loader.svg"
import style from "@/components/core/PageLoader/index.module.scss"
import { isMobile } from "react-device-detect"

const PageLoader = ({ loading = false, initialLoader = false }) => {
  const loadingStates = [
    "Loading.....",
    // "We are working on getting information for you.",
    // "Please wait we are getting information.",
  ]
  useEffect(() => {
    const htmlElem = document.getElementsByTagName("html")[0]
    if (loading) {
      document.body.style["touch-action"] = "none"
      htmlElem.style["touch-action"] = "none"
    } else {
      document.body.style.removeProperty("touch-action")
      htmlElem.style.removeProperty("touch-action")
    }
    return () => {
      document.body.style.removeProperty("touch-action")
      htmlElem.style.removeProperty("touch-action")
    }
  }, [loading])
  const EMPTY_PIXEL =
    "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
  const [src, setSrc] = useState(EMPTY_PIXEL)
  const [currentLoadingState, setCurrentLoadingState] = useState(
    loadingStates[0]
  )
  useEffect(() => {
    if (isMobile) {
      setSrc(EMPTY_PIXEL)
    } else {
      setSrc(pageLoader.src)
    }
  }, [isMobile])

  const shuffle = useCallback(() => {
    const index = Math.floor(Math.random() * loadingStates.length)
    setCurrentLoadingState(loadingStates[index])
  }, [])

  useEffect(() => {
    const intervalID = setInterval(shuffle, 5000)
    return () => clearInterval(intervalID)
  }, [shuffle])

  return loading || initialLoader ? (
    <div className={style.pageLoaderWrapper}>
      <div className="page-loader">
        <img
          className="page-loader__spinner"
          src={src}
          alt=""
          aria-hidden="true"
        />
        <span className="page-loader__message">{currentLoadingState}</span>
      </div>
    </div>
  ) : null
}

PageLoader.propTypes = {
  loading: PropTypes.bool,
}

export default PageLoader
