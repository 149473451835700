import { store } from "@/store/index"
import { apim } from "@/constants/api"
import {
  addToCart,
  setCart,
  setCartCount,
  createCart,
  getCart,
} from "@/store/features/cartSlice"
import { getConfig } from "@/constants/config"
import { getQuantityCount } from "@/utils/cart"
import { showToast } from "@/store/features/genericSlice"
import { getCurrency } from "@/utils/helper"

const {
  productBySkuEndPoint,
  shareCartEndPoint,
  searchPlpEndPoint,
} = require("@/constants/index")

export const getProductsBySku = async skus => {
  let query = ""
  skus.forEach((sku, i) => {
    query += `"${sku}"`
    if (i + 1 !== skus.length) query += ", "
  })
  const res = await apim.get(
    `${productBySkuEndPoint}?limit=50&where=masterVariant(sku in (${query})) or variants(sku in (${query}))`
  )
  if (res && res.data && res.data.results) return res.data.results
  return []
}

export const getProductDetailsBySku = async (skus, category) => {
  let query = ""
  skus.forEach((sku, i) => {
    query += `"${sku}"`
    if (i + 1 !== skus.length) query += ", "
  })
  let brandName = `kohler-india`
  const res = await apim.get(
    `${searchPlpEndPoint}?fl=*&q=*:*&rows=10&collections=kohler-india&fq=sku_s:(${query})&profilename=profile_${brandName.toLowerCase()}_PLP_${category}`
  )
  if (res && res.data) return res?.data?.response?.docs
  return []
}

export const getSharedCart = async id => {
  const res = await apim.get(`${shareCartEndPoint}/${id}`)
  if (res && res.data) return res.data
  return
}

const showToastHandler = msg =>
  store.dispatch(
    showToast({
      message: msg,
      isVisible: true,
    })
  )

export const shareaddToCart = async (items, message, callback, cartItems) => {
  const payload = []
  items.forEach(product => {
    let customObj = {
      type: { key: "cartLineItemAdditionalInfo", typeId: "type" },
    }
    const hasInstallService =
      product?.custom?.fields.hasOwnProperty("associatedService")
    if (product.backorder) customObj.isBackOrderable = true
    const isInCart = cartItems?.find(
      item => item?.variant?.sku === product?.sku
    )
    const isInstallServiceInCart = items?.find(
      item => item?.sku === product?.custom?.fields.associatedService
    )
    const lineItemId = isInCart ? isInCart.id : product.sku
    if (hasInstallService && isInstallServiceInCart) {
      const installService = items?.find(
        item => item?.sku === product?.custom?.fields?.associatedService
      )
      const warehouse = product?.custom?.fields?.warehouses
      customObj = {
        ...customObj,
        fields: {
          warehouses: warehouse,
          associatedService: installService?.sku,
          associatedServiceQty: product.custom.fields?.associatedServiceQty,
        },
      }
    }
    if (isInCart) {
      const hasCartInstallService =
        isInCart?.custom?.fields.hasOwnProperty("associatedService")
      const isInstallCart = cartItems?.find(
        item => item?.variant?.sku === product?.custom?.fields.associatedService
      )
      const warehouse =
        isInCart?.custom?.fields?.warehouses || JSON.stringify({})
      if (hasCartInstallService && isInstallCart) {
        payload.push({
          action: "setLineItemCustomType",
          lineItemId: lineItemId,
          type: {
            key: "cartLineItemAdditionalInfo",
            typeId: "type",
          },
          fields: {
            warehouses: warehouse,
            associatedService: isInstallCart?.variant?.sku,
            associatedServiceQty: isInCart.quantity + isInstallCart.quantity,
          },
        })
        payload.push({
          action: "changeLineItemQuantity",
          lineItemId,
          quantity: product.quantity + isInCart.quantity,
        })
      } else {
        if (hasInstallService) {
          const installService = items?.find(
            item => item?.sku === product?.custom?.fields?.associatedService
          )
          const warehouse =
            installService?.custom?.fields?.warehouses || JSON.stringify({})
          customObj = {
            ...customObj,
            fields: {
              warehouses: warehouse,
              associatedService: installService?.sku,
              associatedServiceQty: product.custom.fields?.associatedServiceQty,
            },
          }
          payload.push({
            action: product.isBundle ? "addBundleItem" : "addLineItem",
            sku: product.sku,
            quantity: product.quantity,
            custom: customObj,
          })
        } else {
          payload.push({
            action: product.isBundle ? "addBundleItem" : "addLineItem",
            sku: product.sku,
            quantity: product.quantity,
            custom: customObj,
          })
        }
      }
    } else {
      payload.push({
        action: product.isBundle ? "addBundleItem" : "addLineItem",
        sku: product.sku,
        quantity: product.quantity,
        custom: customObj,
      })
    }
  })

  const body = {
    actions: payload,
  }
  await store
    .dispatch(addToCart(body))
    .unwrap()
    .then(res => {
      if (res) {
        store.dispatch(setCart(res))
        console.log("res", res)
        const lineItems = res.lineItems || []
        const customLineItems = res.customLineItems || []
        const payload = getQuantityCount([...lineItems, ...customLineItems])
        store.dispatch(setCartCount(payload))
        if (message) {
          showToastHandler(message)
        }
        if (callback) {
          callback({ res })
        }
      }
    })
}

export const generateCartData = async arg => {
  const currency = await getCurrency()
  const body = {
    currency: "INR",
  }

  let cartData = {}
  const { payload: rsp } = await store.dispatch(getCart({ isNotFromCart: arg }))

  if (rsp?.error && rsp?.cartExists === "false") {
    const { payload } = await store.dispatch(createCart(body))
    cartData = payload
  } else {
    cartData = arg === true ? rsp : rsp?.data?.me?.activeCart
  }

  return cartData
}
