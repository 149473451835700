import _get from "lodash/get"
import { getLocationUsingZipcode } from "@/utils/location"
import { setShippingAddress } from "@/components/cart/cartHelper"

export const handleZipChange = async zip => {
  const res = await getLocationUsingZipcode(zip)
  const addr = _get(res?.data, "resourceSets[0].resources[0].address", {})
  const payload = {
    postalCode: zip,
    state: addr.adminDistrict ?? "",
    country: addr.countryRegionIso2 ?? "",
  }
  await setShippingAddress(payload)
  localStorage.setItem("shippingZip", zip)
}

export const formatMobileNumber = (inputNumber = "") => {
  let resultantNumberArray = []
  let error = null
  try {
    const reversedArray = inputNumber
      .split("")
      .reverse()
      .filter(digit => digit.trim())
    reversedArray.forEach((digit, index) => {
      resultantNumberArray.push(digit)
      if (
        index !== 0 &&
        index % 3 === 0 &&
        index !== reversedArray.length - 1
      ) {
        resultantNumberArray.push(" ")
      }
    })
  } catch (err) {
    error = err
    console.error(err)
  } finally {
    return !error ? resultantNumberArray.reverse().join("") : inputNumber
  }
}
export const formatName = address => {
  const { firstName = "", lastName = "" } = address
  let name = ""
  if (firstName) {
    name = name.concat(firstName)
  }
  if (lastName) {
    name = name.concat(" ", lastName)
  }
  return name
}

export const formatAddress = address => {
  let formattedAddress = null
  const {
    streetName = "",
    postalCode = "",
    city = "",
    region = "",
    country = "IN",
  } = address
  const name = formatName(address)
  formattedAddress = (
    <>
      {name ? (
        <>
          {name}
          <br />
        </>
      ) : null}
      {streetName ? <>{streetName + ", "}</> : null}
      {region ? (
        <>
          {region}
          <br />
        </>
      ) : null}
      {city ? <>{city + ", "}</> : null}
      {country ? <>{country + " "}</> : null}
      {postalCode ? (
        <>
          {postalCode}
          <br />
        </>
      ) : null}
    </>
  )
  return formattedAddress
}

export const isBundleWithInstallation = (bundleSku, lineItems) => {
  return Boolean(
    lineItems.find(item => {
      if (item.custom?.fields.parentBundleSku === bundleSku) {
        const category = item.variant?.attributes?.find(
          attr => attr.name === "ProductLocalCategory"
        )
        return category?.value === "Install Services"
      }
    })
  )
}

export const getChildItemsCount = lineItems => {
  const items = lineItems.filter(item => item.category !== "Install Services")
  return items.length
}

export const getCartCount = cartItems => {
  let count = 0
  cartItems?.map(item => {
    if (!item.isBundleChildItem || item.category === "Install Services")
      count += item.quantity
  })
  return count
}
