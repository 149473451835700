import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  applyClicked: false,
}
export const memberSlice = createSlice({
  name: "member",
  initialState,
  reducers: {
    applyAction(state, action) {
      state.applyClicked = true
    },
  },
})
export const { applyAction } = memberSlice.actions
