export const getSessionIdObjWithEpoc = () => {
  const cookies = document.cookie.split(";") ?? []
  let sessionCookie =
    cookies.find(myCookie => myCookie.includes("session#")) ?? ""
  sessionCookie =
    sessionCookie.split("|").find(item => item.includes("session")) ?? ""
  const sessionCookies = sessionCookie.split("#") ?? []
  const sessionId = sessionCookies[1] ?? ""
  return sessionId ? `${sessionId}-${Date.now()}` : ""
}
