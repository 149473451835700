import axios from "axios"
import {
  authorBaseUrl as baseUrl,
  authorPreviewBaseUrl,
  authorPreviewUserName,
  authorPreviewPassword,
  oktaTokenEndpoint,
  validateTokenEndPoint,
  shipmentFromCartEndPoint,
  shippingCostEndPoint,
} from "."

import Cookie from "universal-cookie"
import { store } from "@/store"
import moment from "moment"
import { setAuthData, setRevalidateTime } from "@/store/features/authSlice"
import { setNoshipping } from "@/store/features/cartSlice"
import {
  getAccessToken,
  getAnonymousId,
  addPageLoadAnalyticsEvent,
} from "@/utils/helper"
import { getConfig } from "@/constants/config"
const token = `${authorPreviewUserName}:${authorPreviewPassword}`
const encodedToken = Buffer.from(token).toString("base64")
const aemAxiosInstance = axios.create({
  baseURL: baseUrl,
  timeout: 10000,
  headers: {
    "Content-type": "application/json",
    KohlerTarget: "AzureApp",
  },
})
const aemApimAxiosInstance = axios.create({
  baseURL: process.env.NEXT_PUBLIC_APIBASEURL,
  timeout: 10000,
  headers: {
    "Content-type": "application/json",
    KohlerTarget: "AzureApp",
    "Ocp-Apim-Subscription-Key": process.env.APIM_SUBSCRIPTION_KEY,
  },
  // signal: AbortSignal.timeout(5000)
})

const aemPreviewAxiosInstance = axios.create({
  baseURL: authorPreviewBaseUrl,
  timeout: 10000,
  headers: {
    "Content-type": "application/json",
    KohlerTarget: "AzureApp",
    Authorization: "Basic " + encodedToken,
  },
})

const apim = axios.create({
  timeout: 60000,
  headers: {
    "Content-type": "application/json",
    KohlerTarget: "AzureApp",
  },
})

let validateTokenRequest
let getAnonymousTokenRequest
const validateToken = (accessToken, apimHost) => {
  if (!validateTokenRequest) {
    validateTokenRequest = axios.post(
      `${apimHost}${validateTokenEndPoint}`,
      {},
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    )
    validateTokenRequest.then(
      resetValidateTokenRequest,
      resetValidateTokenRequest
    )
  }

  return validateTokenRequest
}

export const getAnonymousToken = apimHost => {
  let gId
  const token = getAccessToken()
  gId = token.gId
  const cookie = new Cookie()
  cookie.get("commerce_gid") && (gId = cookie.get("commerce_gid"))
  if (!getAnonymousTokenRequest) {
    getAnonymousTokenRequest = axios.post(
      `${apimHost}${oktaTokenEndpoint}?scope=guest&channel=india`,
      null,
      {
        params: {
          gId: gId,
        },
      }
    )
    getAnonymousTokenRequest.then(
      resetGetAnonymousTokenRequest,
      resetGetAnonymousTokenRequest
    )
  }

  return getAnonymousTokenRequest
}

const resetValidateTokenRequest = () => {
  validateTokenRequest = null
  getAnonymousTokenRequest = null
}

const resetGetAnonymousTokenRequest = () => {
  getAnonymousTokenRequest = null
  validateTokenRequest = null
}

apim.interceptors.request.use(
  async config => {
    let apimHost = process.env.NEXT_PUBLIC_APIMBASEURL
    if (apimHost) {
      config.baseURL = apimHost
    } else {
      // set apim baseurl from global config
      const configVal = await getConfig()
      apimHost = configVal?.general?.apimHost
      config.baseURL = apimHost
    }

    const { token: accessToken = "" } = getAccessToken()
    const anonymousId = getAnonymousId()

    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`
    } else if (window && window.authAccessToken) {
      config.headers.Authorization = `Bearer ${window.authAccessToken}`
      window.authAccessToken = ""
    }

    const getNewToken = () => {
      return getAnonymousToken(apimHost)
        .then(res => {
          const { data: { access_token: accessToken = "" } = {} } = res
          const payload = {
            accessToken,
          }
          store.dispatch(setAuthData(payload))
          config.headers.Authorization = `Bearer ${accessToken}`
          return config
        })
        .catch(_error => {
          return Promise.reject(_error)
        })
    }

    addPageLoadAnalyticsEvent()
    const revalidateIn = store.getState()?.auth?.revalidate_in || ""
    const isAfter = moment().isAfter(revalidateIn)

    if (!accessToken) {
      return getNewToken()
    } else if (anonymousId && isAfter) {
      return validateToken(accessToken, apimHost)
        .then(res => {
          if (res.data?.valid) {
            store?.dispatch(setRevalidateTime())
            return config
          } else {
            return getNewToken()
          }
        })
        .catch(() => {
          return getAnonymousToken(apimHost)
            .then(res => {
              const { data: { access_token: accessToken = "" } = {} } = res
              const payload = {
                accessToken,
              }
              store.dispatch(setAuthData(payload))
              config.headers.Authorization = `Bearer ${accessToken}`
              return config
            })
            .catch(_error => {
              return Promise.reject(_error)
            })
        })
    } else {
      return config
    }
  },
  error => {
    return Promise.reject(error)
  }
)

apim.interceptors.response.use(
  async res => {
    return res
  },
  async error => {
    const originalConfig = error.config
    if (
      !store.getState()?.auth?.isAuth &&
      error?.response?.status === 401 &&
      !originalConfig._retry &&
      (error.response.data.message === "JWT not present." ||
        error.response.data.message === "Invalid JWT.")
    ) {
      originalConfig._retry = true
      const { general: { apimHost } = {} } = await getConfig()
      return getAnonymousToken(apimHost)
        .then(res => {
          const { data: { access_token: accessToken = "" } = {} } = res
          const payload = {
            accessToken,
          }
          store.dispatch(setAuthData(payload))
        })
        .catch(_error => {
          return Promise.reject(_error)
        })
    } else if (
      (error?.response?.config?.url?.includes(shipmentFromCartEndPoint) ||
        error?.response?.config?.url?.includes(shippingCostEndPoint)) &&
      error?.response?.data?.error?.message &&
      error?.response?.data?.error?.sku
    ) {
      const productsArray = []
      error?.response?.data?.error?.sku?.forEach(data => {
        productsArray.push({ sku: data })
      })
      await store.dispatch(setNoshipping(productsArray))
    }

    return Promise.reject(error)
  }
)

export { aemAxiosInstance, aemPreviewAxiosInstance, apim, aemApimAxiosInstance }
