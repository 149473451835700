import _get from "lodash/get"
import qs from "qs"
import { apim } from "@/constants/api"
import {
  getPdpTooltipEndPoint,
  pdpCatalogProjectionEndPoint,
  searchPdpEndPoint,
  searchPlpEndPoint,
  SEARCH_PDP_FL,
} from "@/constants/index"
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { HYDRATE } from "next-redux-wrapper"
import { addToRecentlyViewed } from "@/utils/helper"
import { getInitialSku } from "@/components/ProductDetail/v3/productDetailsHelper"

const initialState = {
  pdp: {},
  isDiscontinued: false,
  productInfo: {},
  teaserContent: [],
  isTeaserLoaded: false,
}

export const searchPdp = createAsyncThunk(
  "pdp/searchPdp",
  async (payload, thunkAPI) => {
    try {
      const { slug, isParts, brandName, selectedSku, setSkuVariantCb } = payload

      const response = await apim.post(`${searchPdpEndPoint}/${slug}`, {
        q: "*:*",
        fl: SEARCH_PDP_FL.join(","),
        collections: isParts ? "service_parts" : brandName?.toLowerCase(),
        profilename: isParts
          ? `profile_service_parts_PDP`
          : `profile_${brandName?.toLowerCase()}_PDP`,
        search_type: "product_detail",
      })
      let productData = _get(response.data, "response.docs[0]", {})

      let sku = ""
      let customerFacingSKU = ""
      let fusionQueryId = ""
      let recommendedAccessories = []
      let colors = []

      if (!productData["BundleList_ss"]) {
        sku =
          selectedSku === "" ||
          !productData["variantList.sku_ss"].some(v => v === selectedSku)
            ? getInitialSku(productData, setSkuVariantCb)
            : selectedSku
        customerFacingSKU = productData["CustomerFacingSKU_s"]

        fusionQueryId = _get(response.data, "fusion.fusionQueryId")

        if (productData["ProductProductRecommendedAccessories_ss"]) {
          recommendedAccessories =
            productData["ProductProductRecommendedAccessories_ss"]
        } else if (productData["ProductProductRecommendedAccessories_s"]) {
          recommendedAccessories.push(
            productData["ProductProductRecommendedAccessories_s"]
          )
        }

        if (productData["Color.SKU.Details_ss"]) {
          colors = productData["Color.SKU.Details_ss"].map(color => {
            const d = color.split("|")
            return {
              sku: d[2],
              discontinued: !(
                d[4] === "false" || Date.parse(d[4]) > Date.now()
              ),
            }
          })
        } else if (productData["SKU.Details_ss"]) {
          colors = productData["SKU.Details_ss"].map(color => {
            const d = color.split("|")
            return {
              sku: d[0],
              discontinued: !(
                d[2] === "false" || Date.parse(d[2]) > Date.now()
              ),
            }
          })
        }
      } else {
        const response = await apim.post(
          `${searchPdpEndPoint}/${slug}?bundle=true&slug=${slug}`,
          {
            q: "*:*",
            fl: SEARCH_PDP_FL.join(","),
            collections: brandName?.toLowerCase(),
            profilename: `profile_${brandName?.toLowerCase()}_PDP`,
            search_type: "product_detail",
          }
        )
        productData = _get(response.data, "response.docs[0]", {})
        productData.bundleItems = _get(
          response.data,
          "fusion.bundle-product-results",
          []
        )
        sku =
          selectedSku === "" ||
          !productData["variantList.sku_ss"].some(v => v === selectedSku)
            ? getInitialSku(productData, setSkuVariantCb)
            : selectedSku
        customerFacingSKU = productData["CustomerFacingSKU_s"]
      }

      addToRecentlyViewed(productData["ctId_s"])

      return {
        productData,
        sku,
        recommendedAccessories,
        colors,
        fusionQueryId,
        customerFacingSKU,
        slug,
      }
    } catch (err) {
      return err
    }
  }
)

export const getVariantFields = createAsyncThunk(
  "pdp/getVariantFields",
  async payload => {
    try {
      const { skuList = [], slug, isParts, brandName } = payload
      let skus = ""
      skuList.forEach(s => (skus += `"${s}" `))

      const response = await apim.post(`${searchPdpEndPoint}/${slug}`, {
        q: "*:*",
        fq: `sku_s:(${skus.trim()})`,
        fl: "sku_s,RegionStatesExcluded_ss,RegionSpecialOrder_s",
        collections: isParts ? "service_parts" : brandName.toLowerCase(),
        profilename: isParts
          ? `profile_service_parts_PDP`
          : `profile_${brandName.toLowerCase()}_PDP`,
        search_type: "product_detail",
      })

      return response.data
    } catch (err) {
      return err
    }
  }
)

export const getProductBySlug = createAsyncThunk(
  "pdp/getProductBySlug",
  async payload => {
    let product = {}
    try {
      const { slug } = payload
      const response = await apim.post(
        `${pdpCatalogProjectionEndPoint}?where=slug(en-IN%3D"${slug}")`
      )

      if (response && response.data && response.data.results) {
        product = response.data.results[0] ?? {}
      }
    } catch (err) {
      return err
    }
    return product
  }
)

export const getProductBySlugAndSku = createAsyncThunk(
  "pdp/getProductBySlugAndSku",
  async payload => {
    const { slug, skus } = payload
    let query = ""
    let masterQuery = ""
    if (slug) masterQuery += `slug(en-IN%3D"${slug}") or `
    if (skus.length > 0) {
      skus.forEach((sku, i) => {
        query += `"${sku}"`
        if (i + 1 !== skus.length) query += ", "
      })
      masterQuery += `masterVariant(sku in (${query})) or variants(sku in (${query}))`
    }
    try {
      const res = await apim.get(
        `${pdpCatalogProjectionEndPoint}?where=${masterQuery}`
      )
      if (res && res.data && res.data.results) return res.data.results
    } catch (err) {
      console.error("Failed to fetch product details", err)
    }
    return []
  }
)

export const getReqItemLwData = createAsyncThunk(
  "pdp/getReqItemLwData",
  async payload => {
    const { query, brandName } = payload
    const response = await apim.get(searchPlpEndPoint, {
      params: {
        q: "*:*",
        fl: "ctId_s,Color.SKU.Details_ss,productName_s,ProductBrandNameDisplay_s,productImage.labelWithUrl_ss,RegionOnBackOrder_s",
        fq: `productInRiverId_s:(${query})`,
        collections: brandName.toLowerCase(),
        profilename: `profile_${brandName.toLowerCase()}_General`,
      },
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: "repeat", encode: false })
      },
    })
    return response?.data?.response?.docs ?? []
  }
)

export const fetchPdpRequiredItems = createAsyncThunk(
  "pdp/fetchPdpRequiredItems",
  async query => {
    try {
      const response = await apim.get(
        `${pdpCatalogProjectionEndPoint}?where=key in (${query})`
      )
      return response.data.results ?? []
    } catch (err) {
      return err
    }
  }
)

export const getPdpTooltip = createAsyncThunk(
  "pdp/getPdpTooltip",
  async payload => {
    const { brandName } = payload
    const response = await apim.get(getPdpTooltipEndPoint, {
      params: {
        collection: brandName?.toLowerCase(),
      },
    })
    return response?.data
  }
)

export const pdpSlice = createSlice({
  name: "pdp",
  initialState,
  reducers: {
    setPDP(state, action) {
      state.pdp = { ...state.pdp, ...action.payload }
    },
    getDiscontinuedProductValue(state, action) {
      state.isDiscontinued = action.payload
    },
    setProductInfo(state, action) {
      state.productInfo = action.payload
    },
    setTeaserContent(state, action) {
      state.teaserContent = action.payload
    },
    setIsTeaserLoaded(state, action) {
      state.isTeaserLoaded = action.payload
    },
  },
  extraReducers(builder) {
    builder
      .addCase([HYDRATE], (state, action) => {
        return {
          ...state,
          ...action.payload.pdp,
        }
      })
      .addCase(searchPdp.pending, (state, action) => {
        state.status = "pending"
      })
      .addCase(searchPdp.fulfilled, (state, action) => {
        const {
          productData = {},
          sku = "",
          recommendedAccessories = [],
          colors = [],
          slug = "",
          customerFacingSKU = "",
        } = action.payload
        state.status = "succeeded"
        state.pdp = {
          sku,
          slug: slug,
          recommendedAccessories,
          productBrand: productData["ProductBrandNameDisplay_t"],
          data: productData,
          variants: colors,
          customerFacingSKU: customerFacingSKU
            ? customerFacingSKU.replace("K-", "")
            : "",
        }
      })
      .addCase(searchPdp.rejected, (state, action) => {
        state.status = "failed"
        state.error = action.error.message
      })
  },
})

export const {
  setPDP,
  getDiscontinuedProductValue,
  setProductInfo,
  setTeaserContent,
  setIsTeaserLoaded,
} = pdpSlice.actions
export const selectPdpState = state => state.pdp
export default pdpSlice.reducer
