import { SPECIAL_CHAR_REGEX } from "@/constants/regex"
import { cleanText, sanitizeTextForAnalytics } from "@/utils/helper"

export const addDataLayerFav = (
  msg = "",
  isSuccess = false,
  type = "",
  internalLinkType = "",
  zone = "",
  videoName = "",
  videoId = ""
) => {
  const sharedZoneName = zone?.replace(SPECIAL_CHAR_REGEX, " ") ?? ""
  const { adobeDataLayer: { getState } = {} } = window
  const page = (getState && getState("page")) || {}
  const sharedInternalLinkType = internalLinkType
    ? sanitizeTextForAnalytics(internalLinkType)
    : "teaser"
  const cleanSharedInternalLinkType = cleanText(sharedInternalLinkType)
  const sharedInternalLinkZoneName = sharedZoneName
    ? sanitizeTextForAnalytics(sharedZoneName)
    : "teaser"
  const eventPageType = window.eventPageType
  const pageType = `${eventPageType}:${sharedInternalLinkZoneName}`
  const eventInfo = {
    clickInternalLinks: "true",
    eventAction: `${pageType}:favorite ${type}`,
    eventName: `${pageType}:favorite ${type}`,
    eventMsg: msg || "n/a",
    eventStatus: isSuccess ? "success" : "failure",
    eventType: "navigation",
    internalLinkName: `favorite ${type}`,
    internalLinkPosition: eventPageType,
    internalLinkType: `${eventPageType}:${cleanSharedInternalLinkType}`,
    internalLinkURL: "n/a",
    internalLinkZoneName: `${eventPageType}:${sharedInternalLinkZoneName}`,
  }
  if (videoName) eventInfo.videoName = videoName
  if (videoId) eventInfo.videoId = videoId

  window.adobeDataLayer.push({
    event: "cmp:click",
    eventInfo,
    page,
  })
  delete window.zoneName
  delete window.internalLinkType
}
