import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"

import { apim } from "@/constants/api"
import {
  GET_ORDER_ENDPOINT,
  GET_ORDER_DETAILS_ENDPOINT,
  FIND_MY_ORDERS_ENDPOINT,
  SPLIT_ORDER,
} from "@/constants"
import { getLineItemAttrs } from "@/utils/product"
import { INSTALL_SERVICES } from "@/utils/constants"

const initialState = {
  loading: true,
  error: "",
  orders: [],
}

export const getOrder = createAsyncThunk("getOrder", async orderId => {
  try {
    const res = await apim.get(GET_ORDER_ENDPOINT(orderId))
    return res.data
  } catch (err) {
    console.error(`Failed to fetch order detail for orderId: ${orderId}`, err)
    return err
  }
})

export const getOpenOrders = createAsyncThunk(
  "orders/list/open",
  async (offset = 0) => {
    let data = {}
    try {
      const res = await apim.get(
        `${GET_ORDER_DETAILS_ENDPOINT}?offset=${offset}&state=open`
      )
      if (res && res.data && res.data.results) {
        data.total = res.data.total
        data.results = res.data.results
      }
    } catch (err) {
      console.error("Failed to fetch open orders", err)
    }
    return data
  }
)

export const getPastOrders = createAsyncThunk(
  "orders/list/past",
  async offset => {
    let data = {}
    try {
      const res = await apim.get(
        `${GET_ORDER_DETAILS_ENDPOINT}?offset=${offset}&state=past`
      )
      if (res && res.data && res.data.results) {
        data.total = res.data.total
        data.results = res.data.results
      }
    } catch (err) {
      console.error("Failed to fetch past orders", err)
    }
    return data
  }
)

export const findOrderByEmail = createAsyncThunk(
  "orders/email",
  async payload => {
    const body = {
      email: payload.email?.trim().toLowerCase(),
      orderId: payload.orderNo?.trim().toUpperCase(),
    }
    try {
      const { status, data } = await apim.post(
        `${FIND_MY_ORDERS_ENDPOINT}?${
          payload.source ? "orderSource=" + payload.source : ""
        }`,
        body
      )
      if (status === 200) {
        let {
          order_detail: {
            shipments = [],
            cancellations = [],
            returns: { order: returnsOrder = [] },
          } = {},
        } = data
        const installations = []
        const returnsData = []
        if (shipments.length) {
          const lineItems = []
          shipments.forEach(order => {
            const { shipDetail = [] } = order
            shipDetail.forEach(delivery => {
              if (delivery.item.length) {
                delivery.item.forEach(item => {
                  lineItems.push({ sku: item.sku })
                })
              }
            })
          })

          if (lineItems.length) {
            const productData = await getLineItemAttrs(lineItems)
            shipments.forEach(order => {
              const { shipDetail = [] } = order
              shipDetail.forEach(delivery => {
                if (delivery.item.length) {
                  delivery.item.forEach((item, index) => {
                    const { lwData } = productData.find(
                      prod => prod.sku === item.sku
                    )
                    item.lwData = lwData
                    if (lwData.Product_Category === INSTALL_SERVICES) {
                      installations.push({
                        ...item,
                        trackingNumber: shipDetail[0]?.trackingNumber ?? "",
                        invoiceNumber: order?.udf13 ?? "",
                      })
                      delete delivery.item[index]
                    }
                  })
                }
              })
            })
          }
        }
        if (cancellations && cancellations?.order?.length) {
          const items = cancellations?.order?.reduce(
            (itemsObj, orderObj) => [...itemsObj, ...orderObj.items],
            []
          )
          const productData = await getLineItemAttrs(items)
          items.forEach((item, index) => {
            const { lwData } = productData.find(prod => prod.sku === item.sku)
            item.lwData = lwData

            if (lwData.Product_Category === INSTALL_SERVICES) {
              items.splice(index, 1)
            }
          })
        }
        if (returnsOrder?.length > 0) {
          const items = returnsOrder?.reduce(
            (itemsObj, orderObj) => [...itemsObj, ...orderObj.items],
            []
          )
          const productData = await getLineItemAttrs(items)
          returnsOrder.map((list, index) => {
            if (list?.items?.length) {
              list?.items?.map(item => {
                const { lwData } = productData.find(
                  prod => prod.sku === item.sku
                )
                item.lwData = lwData
                item.return_date = list?.return_date ?? ""
                item.remarks = list?.remarks ?? ""
                item.return_amount = list?.return_amount ?? ""
                item.itemName = item.udf8 ?? ""
                item.viniculumStatus = item?.status ?? ""
                item.status = list?.status ?? ""
              })
            }
          })
        }
        shipments = shipments.filter(o => !o.extOrderNo.includes("SRV"))
        data.order_detail.shipments = shipments
        data.order_detail.installations = installations
        data.order_detail.returns = returnsOrder
        return data
      }
    } catch (err) {
      console.error("Failed to fetch order details", err)
      return {}
    }
  }
)

export const orderSlice = createSlice({
  name: "orders",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getOrder.pending, state => {
        state.loading = true
      })
      .addCase(getOrder.fulfilled, (state, action) => {
        ;(state.loading = false),
          (state.orders = [...state.orders, action.payload])
      })
      .addCase(getOrder.rejected, (state, action) => {
        state.loading = false
        state.error = action.error.message
      })
  },
})

export const getSplitOrder = createAsyncThunk("orderSplit", async payload => {
  const { email = "", orderNo = "" } = payload
  const emailId = email?.trim().toLowerCase()
  const encodeEmail = encodeURIComponent(emailId)
  const response = await apim.get(
    `${SPLIT_ORDER}?parentOrderNumber=${orderNo
      ?.trim()
      .toUpperCase()}&email=${encodeEmail}`
  )
  return response.data
})

export const {} = orderSlice.actions
export const ordersState = state => state.orders
