import MissingImage from "@/public/images/image-missing.png"
import { COMPONENT_TYPES } from "@/constants"
import { getConfig } from "@/constants/config"
import { store } from "@/store"
import { FREIGHT_SHIPPING_TYPES } from "@/constants"

const isContainer = item => {
  return item[":type"]?.includes(COMPONENT_TYPES.CONTAINER)
}

const isProductcard = item => {
  return item[":type"]?.includes(COMPONENT_TYPES.PRODUCTCARD)
}

const isProductcardV3 = item => {
  return item[":type"]?.includes(COMPONENT_TYPES.PRODUCTCARD_V3)
}

const isAuthModal = item => {
  return item[":type"]?.includes(COMPONENT_TYPES.AUTH_MODAL) && item?.hi
}

const isSiteWideWarning = item => {
  return item[":type"]?.includes(COMPONENT_TYPES.SITEWIDE_WARNING)
}

const isNewsLetter = item => {
  return item[":type"]?.includes(COMPONENT_TYPES.NEWSLETTER)
}

const isNewsLetterSignUp = item => {
  return item[":type"]?.includes(COMPONENT_TYPES.NEWSLETTER_SIGNUP)
}

const isCompareProducts = item => {
  return item[":type"]?.includes(COMPONENT_TYPES.COMPARE_PRODUCTS)
}

const isVideo = item => {
  return item[":type"]?.includes(COMPONENT_TYPES.VIDEO)
}

const isSpacer = item => {
  return item[":type"]?.includes(COMPONENT_TYPES.SPACER)
}

const isImage = item => {
  return item[":type"]?.includes(COMPONENT_TYPES.IMAGE)
}

const isImageV3 = item => {
  return item[":type"]?.includes(COMPONENT_TYPES.IMAGEV3)
}

const isUpSellContainer = item => {
  return item[":type"]?.includes(COMPONENT_TYPES.UPSELL_CONTAINER)
}

const isCartLanding = item => {
  return item[":type"]?.includes(COMPONENT_TYPES.CART_LANDING)
}

const isCheckoutLanding = item => {
  return item[":type"]?.includes(COMPONENT_TYPES.CHECKOUT)
}

const isSharedCart = item => {
  return item[":type"]?.includes(COMPONENT_TYPES.SHARED_CART)
}

const isOrderConfirmation = item => {
  return item[":type"]?.includes(COMPONENT_TYPES.ORDER_CONFIRMATION)
}

const isOrderDetails = item => {
  return item[":type"]?.includes(COMPONENT_TYPES.ORDER_DETAILS)
}

const isOrderHistory = item => {
  return item[":type"]?.includes(COMPONENT_TYPES.ORDER_HISTORY)
}

const isOrderStatus = item => {
  return item[":type"]?.includes(COMPONENT_TYPES.ORDER_STATUS)
}

const isPairsWellWth = item => {
  return item[":type"]?.includes(COMPONENT_TYPES.PAIRS_WELL_WITH)
}
const isCarousel = item => {
  return (
    item[":type"]?.includes(COMPONENT_TYPES.CAROUSEL) &&
    !item[":type"]?.includes(COMPONENT_TYPES.CAROUSELSLICK)
  )
}

const isSlick = item => {
  return (
    item[":type"]?.includes(COMPONENT_TYPES.SLICK) &&
    !item?.carouselComponentProperties?.appliedCssClassNames?.includes(
      "carousel-promo-banner"
    )
  )
}

const isPromoBanner = item => {
  return (
    item[":type"]?.includes(COMPONENT_TYPES.SLICK) &&
    item?.carouselComponentProperties?.appliedCssClassNames?.includes(
      "carousel-promo-banner"
    )
  )
}

const isAemForm = item => {
  return item[":type"]?.includes(COMPONENT_TYPES.AEMFORM)
}
const isTeaser = item => {
  return item[":type"]?.includes(COMPONENT_TYPES.TEASER)
}

const isTeaserContainer = data => {
  const types = []
  data[":itemsOrder"] &&
    data[":itemsOrder"].forEach(key => {
      const type = data[":items"][key][":type"]
      if (type) {
        types.push(type)
      }
    })
  return types.every(a => a.includes(COMPONENT_TYPES.TEASER))
}

const isText = item => {
  return item[":type"]?.includes(COMPONENT_TYPES.TEXT)
}

const isTab = item => {
  return item[":type"]?.includes(COMPONENT_TYPES.TAB)
}

const isBrandHeader = data => {
  const types = []
  data[":itemsOrder"] &&
    data[":itemsOrder"].forEach(key => {
      const type = data[":items"][key][":type"]
      if (type) {
        types.push(type)
      }
    })
  return (
    types.find(a => a.includes(COMPONENT_TYPES.BRAND_SWITCHER)) ||
    types.find(a => a.includes(COMPONENT_TYPES.UTILITY_NAV_HEADER))
  )
}

const isHeaderNav = data => {
  const types = []
  data[":itemsOrder"] &&
    data[":itemsOrder"].forEach(key => {
      const type = data[":items"][key][":type"]
      if (type) {
        types.push(type)
      }
    })
  return (
    types.find(a => a.includes(COMPONENT_TYPES.UTILITY_WIDGET)) &&
    types.find(a => a.includes(COMPONENT_TYPES.TABS)) &&
    types.find(a => a.includes(COMPONENT_TYPES.IMAGE))
  )
}

const isFooter = data => {
  const types = []
  data[":itemsOrder"] &&
    data[":itemsOrder"].forEach(key => {
      const type = data[":items"][key][":type"]
      if (type) {
        types.push(type)
      }
    })
  return (
    types.find(a => a.includes(COMPONENT_TYPES.MAIN_FOOTER)) ||
    types.find(a => a.includes(COMPONENT_TYPES.ADDITIONAL_FOOTER)) ||
    types.find(a => a.includes(COMPONENT_TYPES.NEWSLETTER_SIGNIN))
  )
}

const isEmbed = item => {
  return item[":type"]?.includes(COMPONENT_TYPES.EMBED)
}

const isBreadcrumb = item => {
  return item[":type"]?.includes(COMPONENT_TYPES.BREADCRUMB)
}

const isProductList = item => {
  return item[":type"]?.includes(COMPONENT_TYPES.PRODUCTLIST)
}

const isSearch = item => {
  return item[":type"]?.includes(COMPONENT_TYPES.SEARCH)
}

const isToaster = item => {
  return item[":type"]?.includes(COMPONENT_TYPES.TOASTER)
}

const isHelpSupport = item => {
  return item[":type"]?.includes(COMPONENT_TYPES.HELPSUPPORT)
}
const isProductDetails = item => {
  return item[":type"]?.includes(COMPONENT_TYPES.PRODUCT_DETAILS)
}
const isAccountSettings = item => {
  return item[":type"]?.includes(COMPONENT_TYPES.ACCOUNT_SETTINGS)
}

const isMyservice = item => {
  return item[":type"]?.includes(COMPONENT_TYPES.MY_SERVICES)
}
const isMyFavorites = item => {
  return item[":type"]?.includes(COMPONENT_TYPES.MY_FAVORITES)
}
export const isChangePassword = item => {
  return item[":type"]?.includes(COMPONENT_TYPES.CHANGE_PASSWORD)
}

const isMyproducts = item => {
  return item[":type"]?.includes(COMPONENT_TYPES.MY_PRODUCTS)
}

const isProductAccordion = item => {
  return item[":type"]?.includes(COMPONENT_TYPES.PRODUCT_DETAILS_ACCORDION)
}

const isProductReview = item => {
  return item[":type"]?.includes(COMPONENT_TYPES.PRODUCT_DETAILS_REVIEW)
}

const isRegisterOffline = item => {
  return item[":type"]?.includes(COMPONENT_TYPES.REGISTER_OFFLINE_PRODUCTS)
}
const isRegisterOnline = item => {
  return item[":type"]?.includes(COMPONENT_TYPES.REGISTER_ONLINE_PRODUCTS)
}
const isBrowseAllStores = item => {
  return item[":type"]?.includes(COMPONENT_TYPES.BROWSE_ALL_STORES)
}

const isStoreFilter = item => {
  return item[":type"]?.includes(COMPONENT_TYPES.STORE_FILTER)
}

const isProductExfPdp = item => {
  return item[":type"]?.includes(COMPONENT_TYPES.PRODUCT_DETAILS_EXFPDP)
}

const isKnowledgeArticle = item => {
  return item[":type"]?.includes(COMPONENT_TYPES.KNOWLEDGE_ARTICLE)
}
const isLiterature = item => {
  return item[":type"]?.includes(COMPONENT_TYPES.LITERATURE)
}

const isContactUs = item => {
  return item[":type"]?.includes(COMPONENT_TYPES?.CONTACT_US)
}

const isFindingModelNumber = item => {
  return item[":type"]?.includes(COMPONENT_TYPES?.FINDING_MODEL_NUMBER)
}

const isTechnicalSpec = item => {
  return item[":type"]?.includes(COMPONENT_TYPES.TECHNICAL_SPECIFICATION)
}
const isPressLanding = item => {
  return item[":type"]?.includes(COMPONENT_TYPES.PRESS_LANDING)
}
const isFindStore = item => {
  return item[":type"]?.includes(COMPONENT_TYPES.FIND_STORE)
}

const isInPageSearch = item => {
  return item[":type"]?.includes(COMPONENT_TYPES.INPAGE_SEARCH)
}

const isTitle = (item = {}) => {
  return item[":type"]?.includes(COMPONENT_TYPES.TITLE)
}
const isKeySpecifier = (item = {}) => {
  return item[":type"]?.includes(COMPONENT_TYPES.KEY_SPECIFIER)
}

const isDynamicSearch = item => {
  return item[":type"]?.includes(COMPONENT_TYPES.DYNAMIC_SEARCH)
}

const isAssociateMembership = item => {
  return item[":type"]?.includes(COMPONENT_TYPES.ASSOCIATE_MEMBERSHIP)
}

const isInspireVideo = item => {
  return item[":type"]?.includes(COMPONENT_TYPES.INSPIRE_VIDEO)
}

const isImageCluster = item => {
  return item[":type"]?.includes(COMPONENT_TYPES.IMAGE_CLUSTER)
}
const isProfessionalDetail = item => {
  return item[":type"]?.includes(COMPONENT_TYPES.PROFESSIONAL_DETAILS)
}

const isRoomDetail = item => {
  return item[":type"]?.includes(COMPONENT_TYPES.ROOM_DETAIL)
}

const isShopTheRoomDetail = item => {
  return item[":type"]?.includes(COMPONENT_TYPES.SHOP_THE_ROOM_DETAIL)
}
const isShopTheRoomCard = item => {
  return item[":type"]?.includes(COMPONENT_TYPES.SHOP_THE_ROOM_CARD)
}

const isFriendsAndFamily = item => {
  return item[":type"]?.includes(COMPONENT_TYPES.FRIENDS_AND_FAMILY)
}

const isInstallDesignServices = item => {
  return item[":type"]?.includes(COMPONENT_TYPES.INSTALL_DESIGN_SERVICES)
}

const isFindAProByZip = item => {
  return item[":type"]?.includes(COMPONENT_TYPES.FIND_A_PRO_BY_ZIP)
}

const isStoreAppointment = item => {
  return item[":type"]?.includes(COMPONENT_TYPES.STORE_APPOINTMENT)
}

export const isGatedContent = item => {
  return item[":type"]?.includes(COMPONENT_TYPES.GATED_CONTENT)
}

export const isProductCardV4 = item => {
  return item[":type"]?.includes(COMPONENT_TYPES.PRODUCT_CARD_V4)
}

/**
 *
 * @param {Element} e
 * @param {String} url
 */
const onImageError = (e, url) => {
  const imgsrc = e.target.src
  if (imgsrc.includes("&fmt=webp")) {
    e.target.src = imgsrc.replace("&fmt=webp", "")
    return
  }
  const attr = document.createAttribute("errorsrc")
  attr.value = url
  e.target.setAttributeNode(attr)
  e.target.src = MissingImage.src
}

/**
 *
 * @param {Number} width
 * @param {Number} rowCols
 * @param {String} swatchUrl
 * @param {String} assetId
 * @param {Object} presetConfigs
 * @param {Boolean} isForInstallationService
 * @return {String} preset url
 */
const getPresetUrl = (
  width = 0,
  rowCols = 0,
  swatchUrl = "",
  assetId = "",
  presetConfigs = {},
  isForInstallationService = false
) => {
  const {
    Desktop,
    MobilePortrait,
    MobilePortrait1,
    MobilePortrait2,
    MobileLandscape,
    MobileLandscape1,
    MobileLandscape2,
    PotraitCategory,
  } = presetConfigs
  let preset = ""

  const mobileBasedCheckPoint = isForInstallationService ? 1023 : 768
  if (width <= mobileBasedCheckPoint) {
    if (width <= 425) {
      if (rowCols) {
        preset = MobilePortrait2 || MobilePortrait
        if (rowCols === 1) preset = MobilePortrait1 || MobilePortrait
      } else if (MobilePortrait) {
        preset = MobilePortrait
      } else preset = MobilePortrait2
    } else {
      if (rowCols) {
        if (rowCols === 1) preset = MobileLandscape1 || MobileLandscape
        else preset = MobileLandscape2 || MobileLandscape
      } else if (MobileLandscape) {
        preset = MobileLandscape
      } else preset = MobileLandscape1
    }
  } else preset = Desktop

  if (isForInstallationService) {
    return `${swatchUrl}${assetId}?${preset}`
  }

  return `${swatchUrl}${PotraitCategory}?$product_src=is{PAWEB/${assetId}}&${preset}`
}

const alterClass = (elementClass = "", toAdd = null, toRemove = null) => {
  const element = document.getElementsByClassName(elementClass).item(0)
  if (toAdd && element) {
    element.classList.add(toAdd)
  }
  if (toRemove && element) {
    element.classList.remove(toRemove)
  }
}

//  * @param {String} fileName imagename.jpg / imagename
//  * @returns .jpg,.svg / false

const getExtention = fileName => {
  const i = fileName.lastIndexOf(".")
  if (i === -1) return false
  return fileName.slice(i)
}

/**
 *
 * @param {String} str
 * @param {Number} max
 * @param {Number} min
 * @return {Number}
 */
const accordionUniqueId = (str, max, min) => {
  const crypto = window.crypto || window.msCrypto
  const array = new Uint32Array(1)
  const randVal = crypto.getRandomValues(array)[0]
  return `${str}-${Math.floor(randVal * (max - min + 1)) + min}`
}

const ConditionalWrapper = ({ condition, wrapper, children }) =>
  condition ? wrapper(children) : children

const closest = (el, fn) => el && (fn(el) ? el : closest(el.parentNode, fn))

export const removeExtraSpaces = (string = "") => {
  return string.replace(/\s+/g, " ").trim()
}

/**
 *
 * @param {String} swatchUrl
 * @param {String} assetId
 * @param {Object} presetConfigs
 * @param {String} assetAccountName
 * @return {String}
 */
const getPresetThumbnailUrl = (
  swatchUrl,
  assetId,
  presetConfigs,
  assetAccountName
) => {
  const { Thumbnail, LandscapeCategory } = presetConfigs
  return `${swatchUrl}${LandscapeCategory}?$product_src=is{${assetAccountName}/${assetId}}&${Thumbnail}`
}

const recursiveDataFunction = (data, type) => {
  const results = []
  if (data && data[":itemsOrder"]) {
    data[":itemsOrder"].map(key => {
      const item = data[":items"][key]

      if (item[":type"]?.includes(type)) {
        results.push(item)
        return item
      } else {
        const nestedResults = recursiveDataFunction(item, type)
        results.push(...nestedResults)
      }
    })
  }
  return results
}

const getRowCols = (width, isMobile, isTablet) => {
  let rowCols = width < 1024 ? 1 : 3
  switch (true) {
    case width > 1024:
      rowCols = 3
      break
    case (width < 990 && (isMobile || isTablet)) || width < 480:
      rowCols = 2
      break
    default:
      rowCols = 2
  }
  return rowCols
}

export const getBillingAsShippingAddress = (shipping, billing) => {
  const {
    billingStreetName = "",
    billingAptNo = "",
    billingCity = "",
    billingStateCode = "",
    billingZipcode = "",
  } = billing
  const {
    shippingStreetName = "",
    shippingAptNo = "",
    shippingCity = "",
    shippingStateCode = "",
    shippingZipcode = "",
  } = shipping

  return (
    billingStreetName.toLowerCase() === shippingStreetName.toLowerCase() &&
    billingAptNo.toLowerCase() === shippingAptNo.toLowerCase() &&
    billingCity.toLowerCase() === shippingCity.toLowerCase() &&
    billingStateCode.toLowerCase() === shippingStateCode.toLowerCase() &&
    billingZipcode.toLowerCase() === shippingZipcode.toLowerCase()
  )
}
export const getIsSameAddress = (add1, add2) => {
  const {
    streetName: streetName1 = "",
    streetNumber: streetNumber1 = "",
    city: city1 = "",
    state: state1 = "",
    postalCode: postalCode1 = "",
  } = add1
  const {
    streetName: streetName2 = "",
    streetNumber: streetNumber2 = "",
    city: city2 = "",
    state: state2 = "",
    postalCode: postalCode2 = "",
  } = add2

  return (
    streetName1.toLowerCase() === streetName2.toLowerCase() &&
    streetNumber1.toLowerCase() === streetNumber2.toLowerCase() &&
    city1.toLowerCase() === city2.toLowerCase() &&
    state1.toLowerCase() === state2.toLowerCase() &&
    postalCode1.toLowerCase() === postalCode2.toLowerCase()
  )
}

export const getShippingAllTotal = (customLineItems, locale) => {
  const shippings = []
  customLineItems.forEach(item => {
    const itemName = item.name[locale]
    const amount = item?.totalPrice?.centAmount / 100 ?? 0
    if (!shippings.find(el => el.name === itemName)) {
      shippings.push({ total: amount, name: itemName })
    } else {
      const index = shippings.findIndex(code => code.name === itemName)
      shippings[index].total += amount
    }
  })

  return shippings.filter(e => e.name !== "Service")
}

export const getShippingData = async (currencySign, freeShipping) => {
  const { cart: { cart: { customLineItems } = {} } = {} } = store.getState()
  const { internationalization: { locale } = {} } = await getConfig()

  const shippingAllTotal = getShippingAllTotal(customLineItems, locale)
  const freightShippingData =
    shippingAllTotal.find(el => FREIGHT_SHIPPING_TYPES.includes(el?.name)) ?? {}
  const parcelShippingData =
    shippingAllTotal.find(el => !FREIGHT_SHIPPING_TYPES.includes(el?.name)) ??
    {}

  const { total: freightAmount = "" } = freightShippingData
  const { total: parcelAmount = "" } = parcelShippingData

  const freightShippingCost =
    freightAmount > 0 ? `${currencySign}${freightAmount}.00` : freeShipping

  const parcelShippingCost =
    parcelAmount > 0 ? `${currencySign}${parcelAmount}.00` : freeShipping

  return { freightShippingCost, parcelShippingCost }
}

export const getOrderDetailsUrl = async (email, orderNumber) => {
  const config = await getConfig()
  const { general: { orderDetailsPath = "" } = {} } = config
  return `${orderDetailsPath}?orderNumber=${orderNumber}&q=${Buffer.from(
    email
  ).toString("base64")}`
}

export const getPdpRedirectUrl = (pdpUrl, param) => {
  const isHasQueryParams = pdpUrl.includes("?")
  return `${pdpUrl}${isHasQueryParams ? "&" : "?"}${param}`
}

export const isObjectEmpty = object => {
  return Object.keys(object).length === 0
}

export const affirmRefresh = () => {
  const affirmConfig = {
    public_api_key: process.env.NEXT_PUBLIC_AFFIRM_API_KEY,
    script: "https://cdn1.affirm.com/js/v2/affirm.js",
    session_id: "sID",
  }
  ;(function (l, g, m, e, a, f, b) {
    let d
    const c = l[m] || {}
    const h = document.createElement(f)
    const n = document.getElementsByTagName(f)[0]
    const k = function (a, b, c) {
      return function () {
        // eslint-disable-next-line prefer-rest-params
        a[b]._.push([c, arguments])
      }
    }
    c[e] = k(c, e, "set")
    // eslint-disable-next-line prefer-const
    d = c[e]
    c[a] = {}
    c[a]._ = []
    d._ = []
    c[a][b] = k(c, a, b)
    a = 0
    for (
      b =
        "set add save post open empty reset on off trigger ready setProduct".split(
          " "
        );
      a < b.length;
      a++
    )
      d[b[a]] = k(c, e, b[a])
    a = 0
    for (b = ["get", "token", "url", "items"]; a < b.length; a++)
      d[b[a]] = function () {}
    h.async = !0
    h.src = g[f]
    n.parentNode.insertBefore(h, n)
    delete g[f]
    d(g)
    l[m] = c
  })(window, affirmConfig, "affirm", "checkout", "ui", "script", "ready")
}

export const getPayPalPaymentIdFromParams = () => {
  const urlParams = new URLSearchParams(window.location.search)
  return urlParams.get("id")
}

export const getBrandSwitcher = (headerData = {}) => {
  const headPropsItems = headerData[":items"]
  const headerPropsRoot = headPropsItems?.root
  const headerRootItems = headerPropsRoot[":items"]
  const hedaerPropsContainer = headerRootItems?.container_copy_copy
  const headerContainerItems = hedaerPropsContainer[":items"]
  const headerUtilityWidgets = headerContainerItems?.brand_switcher
  return headerUtilityWidgets?.items ?? ""
}

export const handleViewSterlingResults = (brandSwitcher, brandName, sku) => {
  const { link } = brandSwitcher?.find(
    item =>
      item.brandName.toLowerCase().replaceAll(" ", "") ===
      brandName.toLowerCase()
  )

  window.open(`${link}/en/search?keyword=${sku}`, "_blank")
}

export {
  getPresetUrl,
  onImageError,
  isContainer,
  isAuthModal,
  isSiteWideWarning,
  isNewsLetter,
  isNewsLetterSignUp,
  isCompareProducts,
  isVideo,
  isImage,
  isImageV3,
  isSpacer,
  isCarousel,
  isPromoBanner,
  isSlick,
  isCartLanding,
  isPairsWellWth,
  isTeaser,
  isTeaserContainer,
  isText,
  isTab,
  isBrandHeader,
  isHeaderNav,
  isFooter,
  isEmbed,
  isCheckoutLanding,
  isSharedCart,
  isOrderConfirmation,
  alterClass,
  isOrderDetails,
  isOrderHistory,
  isOrderStatus,
  accordionUniqueId,
  getExtention,
  ConditionalWrapper,
  closest,
  isBreadcrumb,
  isProductList,
  isProductDetails,
  isSearch,
  isToaster,
  isHelpSupport,
  isAccountSettings,
  isMyservice,
  isMyproducts,
  isProductAccordion,
  isProductReview,
  isRegisterOffline,
  isRegisterOnline,
  isMyFavorites,
  getPresetThumbnailUrl,
  isAemForm,
  isProductcard,
  isProductcardV3,
  recursiveDataFunction,
  isProductExfPdp,
  isKnowledgeArticle,
  isBrowseAllStores,
  isLiterature,
  isContactUs,
  isFindingModelNumber,
  isTechnicalSpec,
  isPressLanding,
  getRowCols,
  isUpSellContainer,
  isFindStore,
  isStoreFilter,
  isInPageSearch,
  isTitle,
  isKeySpecifier,
  isDynamicSearch,
  isAssociateMembership,
  isInspireVideo,
  isImageCluster,
  isRoomDetail,
  isShopTheRoomDetail,
  isShopTheRoomCard,
  isInstallDesignServices,
  isProfessionalDetail,
  isFriendsAndFamily,
  isFindAProByZip,
  isStoreAppointment,
}
