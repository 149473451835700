export const removeFromCart = item => {
  /**
   * It removeFrom cart for pay load
   */
  const { id, quantity, isBundleParent, sku } = item
  const payload = {}
  if (isBundleParent) {
    payload.action = "removeBundleItem"
    payload.customLineItemId = id
    payload.sku = sku
  } else {
    payload.action = "removeLineItem"
    payload.lineItemId = id
    payload.quantity = quantity
  }
  const actions = [payload]

  return actions
}

export const setRemoveCartPayload = (items = []) => {
  /**
   * It removeFrom cart for payload
   */
  const actions = []
  items.forEach(item => {
    actions.push({
      action: item.isComboSku ? "removebundle" : "removeLineItem",
      lineItemId: item.id,
      quantity: item.quantity,
    })
  })
  return actions
}

export const changeBundleQty = (
  id,
  sku,
  quantity,
  warehouses,
  includeInstallation = false,
  isInCart = true
) => {
  const actions = [
    {
      action: isInCart ? "changeBundleItemQuantity" : "addBundleItem",
      customLineItemId: id,
      sku,
      quantity,
      custom: {
        type: {
          key: "cartLineItemAdditionalInfo",
          typeId: "type",
        },
        fields: {
          warehouses,
        },
      },
    },
  ]
  return actions
}
